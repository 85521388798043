@use "../../variables" as v;

.footer {
  text-align: center;
  font-family: "Zyzol";
  min-height: 2em;

  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: calc(v.$medium-page-width + v.$medium-page-margin)) {
    width: 90%;
  }

  > div {
    color: v.$main-colour;
    span,
    a {
      &:visited {
        color: v.$main-colour;
      }
      color: v.$main-colour;
      text-decoration: none;
      display: inline-block;
      border-radius: 5px;
      padding: 7px;
    }
  }
}
