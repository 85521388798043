@use "../../variables" as v;

.container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-content: center;
  min-height: 10em;

  @media screen and (max-width: calc(v.$small-page-width)) {
    min-height: 7em;
  }

  .text {
    display: block;
    text-decoration: none;
    border-width: 3px;
    border-radius: 10px;
    border-style: solid;
    border-color: v.$main-colour;
    color: v.$main-colour;
    background-color: v.$secondary-colour;
    font-family: "ZyzolHeart";
    font-size: 2em;
    padding: 10px;
    height: fit-content;
    align-self: center;

    @media screen and (max-width: calc(v.$small-page-width)) {
      font-size: 1.2em;
    }
  }
}
