@use "../../variables" as v;

.textCenter {
  padding-top: 1em;
  text-align: center;
}
.subTitle {
  color: v.$secondary-colour;
  font-size: 1.75em;
  font-family: "HowCute";
  line-height: 1.25em;

  @media screen and (max-width: calc(v.$small-page-width)) {
    font-size: 1.2em;
  }
}
.title {
  color: v.$main-colour;
  font-size: 4em;
  font-family: "Zyzol";

  @media screen and (max-width: calc(v.$small-page-width)) {
    font-size: 2em;
  }
}
