@use "reset";

html,
body {
  width: 100%;
  height: fit-content;
}

body {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100%25' width='100%25'%3E%3Cdefs%3E%3Cpattern id='doodad' width='29' height='29' viewBox='0 0 40 40' patternUnits='userSpaceOnUse' patternTransform='rotate(136)'%3E%3Crect width='100%25' height='100%25' fill='rgba(255, 245, 247,1)'/%3E%3Cpath d='M-10-3h60v6h-60z' fill='rgba(255, 229, 237,1)'/%3E%3Cpath d='M-10 37h60v6h-60z' fill='rgba(255, 229, 237,1)'/%3E%3Cpath d='M-10 29.5h60v1h-60z' fill='rgba(254, 215, 226,1)'/%3E%3Cpath d='M29.5-10v60h1v-60z' fill='rgba(254, 215, 226,1)'/%3E%3Cpath d='M7-10v60h6v-60z' fill='rgba(255, 229, 237,1)'/%3E%3C/pattern%3E%3C/defs%3E%3Crect fill='url(%23doodad)' height='200%25' width='200%25'/%3E%3C/svg%3E ");
}

@font-face {
  font-family: "HowCute";
  src: url("./media/HowCute.ttf") format("truetype");
}

@font-face {
  font-family: "Zyzol";
  src: url("./media/Zyzol.otf") format("opentype");
}

@font-face {
  font-family: "ZyzolHeart";
  src: url("./media/ZyzolHeart.otf") format("opentype");
}
