@use "../variables" as v;
$content-padding: 30px;

.main {
  margin-left: auto;
  margin-right: auto;
  width: v.$wide-page-width;
  min-height: 100vh;
  background-image: url("../media/lace.png");
  background-size: 100%;
  background-repeat: repeat-y;
  @media screen and (max-width: calc(v.$wide-page-width + v.$wide-page-margin)) {
    width: 1000px;
    background-image: url("../media/lace.png");
  }
  @media screen and (max-width: calc(v.$medium-page-width + v.$medium-page-margin)) {
    width: 90%;
  }
  @media screen and (max-width: calc(v.$small-page-width)) {
    background-image: url("../media/lace_small.png");
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.content {
  display: flex;
  flex-direction: column;
  width: calc(100% - ($content-padding * 2));
  flex-grow: 1;
  padding-right: $content-padding;
  padding-left: $content-padding;
}
