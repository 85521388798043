@use "../variables" as v;
$content-padding: 30px;

.main {
  width: calc(100% - 200px);
  padding-left: 100px;
  padding-right: 100px;
  min-height: 100vh;
  background-color: #03001c;
  color: white;
  @media screen and (max-width: calc(v.$medium-page-width + v.$medium-page-margin)) {
    width: calc(100% - 100px);
    padding-left: 50px;
    padding-right: 50px;
  }
  @media screen and (max-width: calc(v.$small-page-width)) {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.page {
  display: flex;
  flex-direction: column;
  width: calc(100% - ($content-padding * 2));

  @media screen and (max-width: calc(v.$small-page-width)) {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
  }

  flex-grow: 1;
  padding-right: $content-padding;
  padding-left: $content-padding;
  height: 50vh;
}

.maxWidth {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1200px;
}

.maxHeight {
  height: 100%;
  overflow: auto;
}

.title {
  padding-top: 30px;
  padding-bottom: 10px;
  font-size: xx-large;
  font-weight: lighter;
  font-family: "Arial";
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 20px;

  @media screen and (max-width: calc(v.$small-page-width)) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.date {
  font-size: medium;
  margin-top: auto;
}

.content {
  white-space: pre;
}
