@use "../../variables" as v;

.img {
  margin-top: 5em;
  display: block;
  width: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;

  border-width: 3px;
  border-style: solid;
  border-color: v.$secondary-colour;
  border-radius: 10px;

  @media screen and (max-width: calc(v.$wide-page-width + v.$wide-page-margin)) {
    max-width: 850px;
    margin-top: 2em;
  }
}

.desktop {
  @media screen and (max-width: calc(v.$small-page-width)) {
    display: none;
  }
}
.mobile {
  font-family: "Zyzol";
  display: none;
  color: v.$main-colour;
  @media screen and (max-width: calc(v.$small-page-width)) {
    display: block;
  }
}
